






























































































































import {Component, Vue} from 'vue-property-decorator';

@Component({
  name: 'Notes'
})
export default class Notes extends Vue {
  clipboardWriteEnable = false;
  toastShow = false

  public async mounted(): Promise<void> {
    const permissionName = 'clipboard-write' as PermissionName;
    const result = await navigator.permissions.query({name: permissionName})

    this.clipboardWriteEnable = result.state === 'granted' || result.state === 'prompt';
  }

  public async copy(event): Promise<void> {
    // console.log(event.target.parentElement.innerText)
    const code = event.target.getAttribute('data-code');
    const $code = this.$refs[code] as HTMLElement
    const txt = $code.innerText.trim()
    await navigator.clipboard.writeText(txt);
    this.toastShow = true

    setTimeout(() => {
      this.toastShow = false
    }, 5000);
  }
}
